<template>
  <div>
    <div class="title">
      Благодарим за поддержку участников!
    </div>
    <div class="text">
      <p>Во время проведения Конкурса на странице поддержки каждый гражданин Российской Федерации, достигший возраста 18
        лет, имел возможность:</p>
      <ul>
        <li>ознакомиться с профилями участников;</li>
        <li>оказать поддержку одному из участников;</li>
        <li>задать вопрос участникам, успешно завершившим дистанционный этап.</li>
      </ul>
      <p><b>28 октября 2020 года определены победители Всероссийского конкурса «Лидеры России. Политика».</b></p>
      <p>Ознакомиться с информацией о победителях Вы можете на официальном сайте Конкурса в разделе
        <a href="https://xn--d1abablabbpgg2am0ahn0gzd.xn--p1ai/news/tpost/hvd2atdf11-obyavleni-pobediteli-konkursa-lideri-ros"
           rel="noopener noreferrer" target="_blank">«Новости»</a>.</p>

      <p>Благодарим за интерес, проявленный к Конкурсу, и поддержку участников!</p>

      <div class="signature">
        С уважением,<br>
        Организационный комитет конкурса «Лидеры России. Политика»
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VoteStub"
}
</script>

<style scoped>
.text{
  text-align: left;
}
p {
  margin-top: 5px;
  margin-bottom: 10px;
}

ul {
  margin-left: 40px;
  margin-bottom: 20px;
}

li {
  list-style-type: disc;
  margin-bottom: 5px;
}

.signature {
  margin-top: 20px;
  margin-bottom: 0;
  font-style: italic;
}
</style>
<template>
  <div>
    <div class="title">
      Личный кабинет
    </div>
    <div class="text">
      Информируем, что личный кабинет участника конкурса "Лидеры России.Политика" закрыт в связи с завершением отборочных этапов.
    </div>
  </div>
</template>

<script>
export default {
  name: "LkStub"
}
</script>

<style scoped>
</style>
<template>
  <div id="app">
    <div class="wrap">
      <div class="logo">
        <img src="@/assets/logo.svg" alt="Политические лидеры"/>
      </div>
      <div class="content">
        <component v-if="!loading" :is="resolvedComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>

import LkStub from "@/components/LkStub";
import VoteStub from "@/components/VoteStub";
export default {
  name: 'App',
  components: {
    VoteStub,
    LkStub,
  },
  data(){
    return {
      sub : '',
      loading: true,
    };
  },
  computed: {
    resolvedComponent(){
      if(this.sub === 'xn--80adc6aobaxm4h') {
        return 'VoteStub';
      }
      return 'LkStub';
    }
  },
  mounted() {
    this.sub = window.location.hostname.split('.')[0];
    this.loading = false;
  },
}
</script>

<style>
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url(@/assets/fonts/Roboto-Light.woff2) format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url(@/assets/fonts/Roboto-Regular.woff2) format('woff2');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url(@/assets/fonts/Roboto-Bold.woff2) format('woff2');
}

html, body{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}
#app {
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  box-sizing: content-box;
  margin: 0 auto;
  /*background-color: #e7effa;*/
  background-repeat: no-repeat;
  background-position: top left, top right, bottom right, bottom left;
  background-image: url('@/assets/bg-top-left.svg'),
                    url('@/assets/bg-top-right.svg'),
                    url('@/assets/bg-bottom-right.svg'),
                    url('@/assets/bg-bottom-left.svg');
  background-size: 27%, 29%, 28%, 35%;
  width: 100%;
  min-height: 100%;
}
.logo{
  display: flex;
  justify-content: center;
  padding-top: 20px;
}
.content{
  background-color: #fff;
  color: #333;
  box-shadow: 0 4px 20px rgba(0,0,0,.25);
  min-height: 400px;
  min-width: 550px;
  max-width: 700px;
  margin-bottom: 20px;
}
.title{
  box-sizing: border-box;
  width: 100%;
  background-color: #f1ffe3;
  padding: 30px;
  font-size: 30px;
}
.text{
  box-sizing: border-box;
  padding: 20px;
}
</style>
